<template>
  <div class="CooperateUs">
    <!-- 头部 -->

    <div class="topbar" style="text-align: left;">
      <img @click="$router.push('/UserInfo')" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>

      <div class="head-title">{{ $t("問題反饋") }}</div>

      <img  class="icon2"  @click="$router.push('/NewChat')" :src='require("@/assets/imgs/edit.png")' style="width: 0.66667rem;height: 0.66667rem;"/>
    
    </div>
    <!-- 没有问题反馈信息图片 -->
    <div class="blankQuestion-img" v-if="!MesssageList.length">
      <img :src="blankQuestionImg" alt="" />
    </div>
    <!-- 反馈信息列表 -->
    <div class="question-type" v-else>
      <ul>
        <li
          v-for="(item, index) in MesssageList"
          :key="index"
          @click="toChat(item.typeName, item.typeId, item.status, item.titleId)"
        >
          <div class="left">
            <p class="p1">{{ item.createTime }}</p>
            <h3>{{ item.typeName }}</h3>
            <p class="p2">{{ item.message }}</p>
          </div>
          <div class="right">
            <!-- 消息红点 -->
            <span class="dot" v-if="item.count != 0">{{ item.count }}</span>
            <!-- 消息处理类型 -->
            <span
              class="result"
              v-if="item.status == 0"
              style="background: #fcf1dd; color: #cf9d41"
              >{{ $t("未處理") }}</span
            >
            <span
              class="result"
              v-if="item.status == 1"
              style="background: #e0ffe9; color: #3fc61d"
              >{{ $t("已處理") }}</span
            >
            <span
              class="result"
              v-if="item.status == 2"
              style="background: #e7e7ee; color: #1d6ac6"
              >{{ $t("處理中") }}</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 问题反馈信息
      MesssageList: [],
      // 没有问题反馈信息图片
      blankQuestionImg: require("@/assets/imgs/blankQuestion-img.png"),
      icon1: require("@/assets/imgs/CooperateUs-icon1.png"),
      icon2: require("@/assets/imgs/CooperateUs-icon2.png"),
    };
  },
  created() {
    this.getListData();
  },
  methods: {
    //   跳转聊天页面
    toChat(typeName, typeId, status, titleId) {
      this.$router.push({
        name: "Chat",
        params: {
          typeId: typeId,
          typeName: typeName,
          titleId: titleId,
          status: status,
        },
      });
      //存储问题类型 处理状态码
      var obj = {
        typeId: typeId,
        typeName: typeName,
        titleId: titleId,
        status: status,
      };
      localStorage.setItem("ChatItem", JSON.stringify(obj));
    },
    // 获取消息列表
    getListData() {
      this.$get("/Chat/App_ListData").then((res) => {
        this.MesssageList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" >
#app{
  
  background-color: #F0F6F9;
}
</style>

<style lang="scss" scoped>
.CooperateUs {
  padding-top: 46px;
  background-color: #F0F6F9;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    height: 1.17333rem;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .icon1 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .icon2 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }
  h3 {
    font-size: 18px;
    text-align: left;
  }
  .choice {
    margin-top: 20px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      border-radius: 10px;
      padding: 25px 0;
      width: 48%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f5f7fb;
      .icon {
        img {
          display: block;
        }
        margin-bottom: 5px;
      }
      span {
        color: #666666;
        font-size: 16px;
      }
    }
  }
  .blankQuestion-img {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding: 0 24px;
    img {
      display: block;
      width: 200px;
    }
  }
  .question-type {
    padding: 0 24px;
    ul {
      padding: 0;
      margin-top: .5rem;
      display: flex;
      flex-direction: column;
      li {
        padding: 0.26667rem 0.32rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 0.21333rem 0.4rem rgb(207 222 228 / 30%);
    border-radius: 0.26667rem;
    margin-bottom: 0.53333rem;

        .left {
          p {
            margin: 0;
            font-size: 14px;
line-height: 15px;
color: #95B6C7;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          h3{
            font-weight: 400;
font-size: 16px;
line-height: 20px;

color: #485573;
          }
        }

        .right {
          display: flex;
          justify-content: center;
          align-items: center;
          .dot {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: #dd524d;
            font-size: 12px;
            color: #fff;
          }
          .result {
            font-size: 14px;
            padding: 3px 10px;
            text-align: center;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>